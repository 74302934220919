import { Link } from '@remix-run/react';
import { LinkBreak } from '@phosphor-icons/react/dist/csr/LinkBreak';
import { Image } from '@unpic/react';

import {
  Container,
  ContainerContent,
  Frame,
  Stack,
} from '#app/components/layouts';
import notFoundBg from '/img/not-found-bg.jpg';

function ErrorNotFound() {
  return (
    <Container className={`relative h-screen`}>
      <Frame align="start" className="z-10 bg-primary-600 *:!justify-center">
        <Image
          src={notFoundBg}
          layout="constrained"
          aspectRatio={1}
          width={5000}
          className="absolute left-0 top-0 !w-screen object-cover"
          alt=""
        />
        <ContainerContent className="pt-[125px]">
          <Stack align="center" className="text-white">
            <LinkBreak size={56} className="mb-1" />
            <h1 className="text-3xl font-medium">404 Page Not Found</h1>
            <p className="text-sm font-medium">
              Sometimes unexpected things happen.
            </p>
            <p className="mb-4 text-sm">
              Please reach out to our sales team and we'll <br /> help you find
              exactly what you're looking for.
            </p>
            <Link
              to="/contact-us"
              className="border border-neutral px-14 py-[11px] text-sm font-medium"
              prefetch='intent'
            >
              Contact Us
            </Link>
          </Stack>
        </ContainerContent>
      </Frame>
    </Container>
  );
}

export default ErrorNotFound;
